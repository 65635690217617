import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import isEqual from "lodash/isEqual"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import {
	AppliedPromotions,
	CheckOrderItemsResult,
	DefaultAccessorial,
	GenericLineItem,
	GenericLineItemWithPart,
	GenericTaxLineItem,
	LineItemType,
	makeApiErrorMessage,
	TaxPricingPost,
	useCheckOrderItems,
	useCreatePartOrder,
	useCustomer,
	useDefaultAccessorials,
	usePartOrder,
	usePrevious,
	useUploadPartOrderAttachment,
	useTaxEstimate,
} from "@ncs/ncs-api"
import { noNullish, unpythonify, validatePhone } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmExitIntent,
	Divider,
	ErrorText,
	FileInput,
	GridContainer,
	GridItem,
	Heading,
	IconButton,
	Label,
	LoadingSpinner,
	Paragraph,
	Price,
	TextareaFormField,
	useIsSaving,
	useScrollToTop,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { partOrderAttachmentFileTypes } from "~/util/part-orders"

import {
	assemblePartOrderPost,
	CreatePartOrderForm,
	CreatePartOrderFormSchema,
	defaultCreatePartOrderFormValues,
	partOrderPostLineItemToLineItem,
} from "../../part-orders-util"
import {
	CreatePartOrderCustomer,
	CreatePartOrderLineItems,
	CreatePartOrderShipping,
} from "./components"

export const PartOrdersCreateOrderTab: FC = () => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const history = useHistory()
	const [{ partOrderId }] = useUrlState({ partOrderId: null })
	const [partOrder, partOrderLoading] = usePartOrder(partOrderId)
	const [lineItemsToSubmit, setLineItemsToSubmit] = useState<GenericLineItem[]>([])
	const scrollToTop = useScrollToTop()
	const taxEstimate = useTaxEstimate()

	const partOrderFormValues: CreatePartOrderForm = useMemo(() => {
		const partOrderForm: CreatePartOrderForm = defaultCreatePartOrderFormValues
		if (partOrder) {
			partOrder.alternateAddressId &&
				(partOrderForm.alternateAddressId = partOrder.alternateAddressId.toString())
			partOrderForm.isInternationalAddress =
				partOrder.billToCustomer.territory?.isDomestic ?? false
			partOrderForm.billToId = partOrder.billToCustomer.id
			partOrderForm.contactPhone =
				partOrder.contactPhone ? partOrder.contactPhone?.toString() : ""
			partOrderForm.isHold = false
			partOrderForm.machineDown = partOrder.isMachineDown
			partOrderForm.onlinePurchase = partOrder.onlinePurchase
			partOrderForm.shipComplete = partOrder.shipComplete
			partOrderForm.shipmentMethodId = partOrder.shipMethod.id
			partOrderForm.siteId = partOrder.shipToCustomer.id
			partOrderForm.internalComment = partOrder.internalComment
			partOrderForm.invoiceComment = partOrder.comment
		}
		return partOrderForm
	}, [partOrder])

	useEffect(() => {
		const mappedLineItems: GenericLineItem[] =
			partOrder ? partOrder.lineItems.map((li) => partOrderPostLineItemToLineItem(li)) : []

		const calculatePartLineTaxes = async (lines: GenericLineItem[]) => {
			// this is function takes the lines from a copied part order and sends them to the
			// tax_estimate/ endpoint on the ncsportal api to recalculate tax rates for each line item incase there was an update.
			if (partOrder) {
				const taxLines: GenericTaxLineItem[] = partOrder.lineItems.map((line) => {
					const taxLine: GenericTaxLineItem = {
						partNumber:
							line?.part?.partNumber ? line?.part?.partNumber : line?.description,
						amount: Number(line.unitPrice),
						quantity: Number(line.quantity),
					}

					return taxLine
				})

				const taxPayload: TaxPricingPost = {
					customerNumber: partOrder.billToCustomer.customerNumber,
					shipTo: {
						line2: partOrder.shipToCustomer.address2,
						city: partOrder.shipToCustomer.city,
						region: partOrder.shipToCustomer.state,
						country: "US",
						postalCode: partOrder.shipToCustomer.postalcode,
					},
					lines: taxLines,
				}

				const response = await taxEstimate(taxPayload)
				const calculatedTaxLines = response.data

				const taxedLines: GenericLineItem[] = lines.map((tempLine) => {
					const taxLine = calculatedTaxLines.lines.find(
						(line) =>
							(tempLine.part?.partNumber === line.part_number ||
								tempLine.description === line.part_number) &&
							tempLine.quantity === line.quantity
					)

					const taxedLine: GenericLineItem = {
						id: tempLine.id,
						lineTypeId: tempLine.lineTypeId,
						part: tempLine.part,
						description: tempLine.description,
						quantity: tempLine.quantity,
						finalPrice: tempLine.finalPrice,
						basePrice: taxLine ? taxLine.unit_price : tempLine.basePrice,
						requestedPrice: tempLine.requestedPrice,
						taxRate: taxLine ? taxLine.tax / taxLine.sub_total : tempLine.taxRate,
						subtotal: taxLine ? taxLine.sub_total : tempLine.subtotal,
						priceOverrideReason: tempLine.priceOverrideReason,
						overridePermission: tempLine.overridePermission,
						reasonComment: tempLine.reasonComment,
						systemGeneratedLine: tempLine.systemGeneratedLine,
						originalSystemGeneratedPrice: tempLine.originalSystemGeneratedPrice,
						overrideApprovalPending: tempLine.overrideApprovalPending,
						isBillable: tempLine.isBillable,
						isUnderWarranty: tempLine.isUnderWarranty,
					}

					return taxedLine
				})
				setLineItemsToSubmit(taxedLines)
			}
		}
		void calculatePartLineTaxes(mappedLineItems)
	}, [partOrder, taxEstimate])

	const form = useForm<CreatePartOrderForm>({
		mode: "onChange",
		resolver: zodResolver(CreatePartOrderFormSchema),
		defaultValues: partOrderFormValues,
	})

	const {
		handleSubmit,
		watch,
		formState: { isValid: formIsValid, submitCount },
		setValue,
		control,
	} = form

	const values = watch()
	const { siteId: selectedShipToId, billToId: selectedBillToId } = values

	const createPartOrder = useCreatePartOrder()
	const uploadAttachment = useUploadPartOrderAttachment()
	const [shipToCustomer, shipToLoading] = useCustomer(selectedShipToId)
	const [billToCustomer, billToLoading] = useCustomer(selectedBillToId, {
		queryConfig: { retry: false },
	})
	const checkOrderItems = useCheckOrderItems()
	const [defaultAccessorials, defaultAccessorialsLoading] =
		useDefaultAccessorials(selectedShipToId)
	const defaultAccessorialsApplied = useRef<string[]>([])

	const [appliedPromotions, setAppliedPromotions] = useState<AppliedPromotions[]>([])
	const [checkOrderItemsResult, setCheckOrderItemsResult] =
		useState<CheckOrderItemsResult | null>(null)
	const [attachments, setAttachments] = useState<File[]>([])
	const { isSaving, setSaving, endSaving } = useIsSaving<"submitting" | "checking-items">()

	const [submissionErrorText, setSubmissionErrorText] = useState<string | null>(null)
	const [customersErrorText, setCustomersErrorText] = useState<string | null>(null)
	const [lineItemErrorText, setLineItemErrorText] = useState<string | null>(null)

	const onInvalid = async () => {
		scrollToTop()
		makeErrorToast(
			"Creating this order could not be completed because there are invalid fields above."
		)
		return
	}

	const onSubmit = async (formData: CreatePartOrderForm) => {
		if (lineItemsToSubmit.length < 1) {
			setSubmissionErrorText("At least 1 line item is required")
			return
		}
		if (!shipToCustomer?.servicable || !billToCustomer?.servicable) {
			// The error text should be there already, we just need to prevent submission.
			scrollToTop()
			return
		}
		if (!checkOrderItemsResult) {
			setSubmissionErrorText(
				"Freight charge not yet calculated, please try again in a moment..."
			)
			return
		}
		if (
			formData.isInternationalAddress === false &&
			validatePhone(formData.contactPhone) === false
		) {
			setSubmissionErrorText("Delivery contact phone number appears to be invalid")
			return
		}

		const partOrderPost = assemblePartOrderPost(formData, lineItemsToSubmit, appliedPromotions)

		try {
			setSaving("submitting")
			const response = await createPartOrder(partOrderPost)
			const newOrder = unpythonify(response.data)

			// If there are any attachments, upload them now that we have the new Part Order ID.
			await Promise.all([
				...attachments.map((attachment) => uploadAttachment(attachment, newOrder.id)),
			])

			makeSuccessToast(`Order #${newOrder.orderId} created`)
			history.push(`/part-orders/${newOrder.id}`)
		} catch (e) {
			setSubmissionErrorText(makeApiErrorMessage(e))
			endSaving("submitting")
		}
	}

	const handleAttachmentSelect = (file: File) => {
		setAttachments((prev) => [...prev, file])
	}

	const handleRemoveAttachment = (indexToRemove: number) => {
		setAttachments((prev) => prev.filter((attachment, i) => i !== indexToRemove))
	}

	const partLineItems: GenericLineItemWithPart[] = useMemo(
		() => lineItemsToSubmit.filter((line): line is GenericLineItemWithPart => !!line.part?.id),
		[lineItemsToSubmit]
	)
	const prevPartLineItems = usePrevious(partLineItems)

	const orderHasChm = useMemo(() => {
		return lineItemsToSubmit.some((line) => line.part?.partFamily === "CHM")
	}, [lineItemsToSubmit])

	// Call the check items endpoint when the part line items change.
	useEffect(() => {
		const checkItems = async () => {
			setSaving("checking-items")
			const response = await checkOrderItems({
				customerId: selectedShipToId,
				locationId: null, // Should this be something else?,
				items: partLineItems.map((line) => {
					return {
						partId: line.part.id,
						quantity: line.quantity,
						unitPrice: line.finalPrice,
						description: undefined,
						partNumber: undefined,
					}
				}),
			})
			endSaving("checking-items")

			const data = unpythonify(response.data)
			setCheckOrderItemsResult(data)

			// Take the line items from result and add them into our current list.
			const newFreightLine = data.freightTotals
			const newSurchargeLine = data.fuelSurcharge

			// We also got a bunch of prices for accessorials.
			const accessorialPrices = Object.fromEntries(
				(data.accessorials ?? []).map((accessorial) => {
					return [accessorial.lineItemTypeId, accessorial]
				})
			)

			setLineItemsToSubmit((prev) => {
				// If any of the previous line items are the same type that we're about to create, blow them away!
				const typesToReplace = noNullish([newFreightLine, newSurchargeLine]).map(
					(line) => line.lineItemType
				)

				const newLines: GenericLineItem[] = prev.flatMap(
					(currentLine): GenericLineItem[] => {
						if (typesToReplace.includes(currentLine.lineTypeId)) {
							return []
						} else if (
							!newFreightLine &&
							currentLine.systemGeneratedLine &&
							currentLine.lineTypeId === LineItemType.Freight
						) {
							// If the return did not have a freight line, then remove the previously system generated
							// freight line, if it was there.
							return []
						} else if (
							!newSurchargeLine &&
							currentLine.systemGeneratedLine &&
							currentLine.lineTypeId === LineItemType.FuelSurcharge
						) {
							// Same thing as above but for fuel surcharge.
							return []
						} else if (accessorialPrices[currentLine.lineTypeId]) {
							// If we have an accessorial price for this line, replace it.
							return [
								{
									...currentLine,
									quantity: 1, // Accessorials are always 1 quantity.
									finalPrice: accessorialPrices[currentLine.lineTypeId].rate,
									subtotal: accessorialPrices[currentLine.lineTypeId].rate,
								},
							]
						} else {
							return [currentLine]
						}
					}
				)

				if (newFreightLine) {
					if (newFreightLine.freightLineVisible) {
						newLines.push({
							description: "Freight",
							lineTypeId: LineItemType.Freight,
							part: null,
							quantity: 1,
							finalPrice: newFreightLine.price,
							basePrice: newFreightLine.price,
							taxRate: 0,
							subtotal: newFreightLine.price,
							priceOverrideReason: null,
							overridePermission: newFreightLine.overridePermission,
							requestedPrice: null,
							reasonComment: null,
							systemGeneratedLine: true,
							originalSystemGeneratedPrice: newFreightLine.price,
						})
					}
				}
				if (newSurchargeLine) {
					newLines.push({
						description: "Fuel Surcharge",
						lineTypeId: LineItemType.FuelSurcharge,
						part: null,
						quantity: 1,
						finalPrice: newSurchargeLine.price,
						basePrice: newSurchargeLine.price,
						taxRate: 0,
						subtotal: newSurchargeLine.price,
						priceOverrideReason: null,
						overridePermission: newSurchargeLine.overridePermission,
						requestedPrice: null,
						reasonComment: null,
						systemGeneratedLine: true,
						originalSystemGeneratedPrice: newSurchargeLine.price,
					})
				}

				return newLines
			})
		}

		if (
			partLineItems.length &&
			selectedShipToId &&
			!isEqual(partLineItems, prevPartLineItems)
		) {
			void checkItems()
		}
	}, [
		selectedShipToId,
		checkOrderItems,
		setValue,
		setSaving,
		endSaving,
		partLineItems,
		prevPartLineItems,
	])

	const createDefaultAccessorials = useCallback(
		(
			customerId: string,
			accessorialsToCreate: DefaultAccessorial[],
			accessorialPrices: NonNullable<CheckOrderItemsResult["accessorials"]>
		) => {
			defaultAccessorialsApplied.current = [
				...defaultAccessorialsApplied.current,
				customerId,
			]

			const newAccessorialLines: GenericLineItem[] = accessorialsToCreate.flatMap(
				(acc): GenericLineItem[] => {
					const match = accessorialPrices.find(
						(a) => a.lineItemTypeId === acc.lineItemTypeId
					)
					if (!match) return []

					return [
						{
							description: acc.description,
							lineTypeId: acc.lineItemTypeId,
							part: null,
							quantity: 1,
							finalPrice: match.rate,
							basePrice: match.rate,
							taxRate: 0,
							subtotal: match.rate,
							priceOverrideReason: null,
							overridePermission: null,
							requestedPrice: null,
							reasonComment: null,
							systemGeneratedLine: true,
							originalSystemGeneratedPrice: match.rate,
						},
					]
				}
			)
			setLineItemsToSubmit((prev) => [
				...prev.filter((l) =>
					newAccessorialLines.every((a) => a.lineTypeId !== l.lineTypeId)
				),
				...newAccessorialLines,
			])
		},
		[]
	)

	useEffect(() => {
		if (
			orderHasChm &&
			defaultAccessorialsApplied.current.includes(selectedShipToId) === false &&
			checkOrderItemsResult?.accessorials &&
			!defaultAccessorialsLoading &&
			defaultAccessorials
		) {
			createDefaultAccessorials(
				selectedShipToId,
				defaultAccessorials,
				checkOrderItemsResult.accessorials
			)
		}
	}, [
		defaultAccessorialsLoading,
		createDefaultAccessorials,
		orderHasChm,
		selectedShipToId,
		checkOrderItemsResult,
		defaultAccessorials,
	])

	const { orderSubtotal, orderTaxes, orderDiscounts, orderTotal } = useMemo(() => {
		const lineItemsSubtotal = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity,
			0
		)
		const lineItemsTaxes = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity * lineItem.taxRate,
			0
		)
		const subtotal = lineItemsSubtotal
		const discounts = appliedPromotions.reduce(
			(discountsTotal, promo) => discountsTotal + promo.discount,
			0
		)

		const total = subtotal + lineItemsTaxes - discounts

		return {
			orderSubtotal: subtotal,
			orderTaxes: lineItemsTaxes,
			orderDiscounts: discounts,
			orderTotal: total,
		}
	}, [lineItemsToSubmit, appliedPromotions])

	useEffect(() => {
		if (!!shipToCustomer && shipToCustomer.servicable === false) {
			setCustomersErrorText(
				"Your Ship To selection is invalid because the customer is not serviceable."
			)
		} else if (!!billToCustomer && billToCustomer.servicable === false) {
			setCustomersErrorText(
				"Your Bill To selection is invalid because the customer is not serviceable."
			)
		} else if (!!shipToCustomer && !billToCustomer && !billToLoading && !!selectedBillToId) {
			setCustomersErrorText("Unable to validate if the selected Bill To is serviceable.")
		} else {
			setCustomersErrorText(null)
		}
	}, [billToCustomer, shipToCustomer, billToLoading, selectedShipToId, selectedBillToId])

	const canSubmit =
		formIsValid &&
		lineItemsToSubmit.length > 0 &&
		billToCustomer?.servicable &&
		shipToCustomer?.servicable

	return (
		<FormProvider {...form}>
			{(billToLoading || shipToLoading) && <LoadingSpinner />}

			{!billToLoading && !shipToLoading && !defaultAccessorialsLoading && (
				<CreatePartOrderCustomer
					shipToCustomer={shipToCustomer}
					customersErrorText={customersErrorText}
					recentPhoneNumbers={shipToCustomer?.freightPhone ?? []}
				/>
			)}

			{partOrderLoading && <LoadingSpinner />}
			{!partOrderLoading && (
				<CreatePartOrderLineItems
					lineItemsToSubmit={lineItemsToSubmit}
					setLineItemsToSubmit={setLineItemsToSubmit}
					lineItemErrorText={lineItemErrorText}
					setLineItemErrorText={setLineItemErrorText}
					orderSubtotal={orderSubtotal}
					orderTaxes={orderTaxes}
					orderDiscounts={orderDiscounts}
					orderTotal={orderTotal}
					appliedPromotions={appliedPromotions}
					setAppliedPromotions={setAppliedPromotions}
					checkOrderItemsResult={checkOrderItemsResult}
				/>
			)}

			<CreatePartOrderShipping shipToCustomer={shipToCustomer} orderHasChm={orderHasChm} />

			<Divider mt={3} mb={1} />

			<GridContainer mb={2}>
				<GridItem xs={12} sm={6}>
					<TextareaFormField
						control={control}
						name="internalComment"
						label="Internal comments (not shown to customer)"
						placeholder="Any internal comments..."
						maxLength={500}
					/>
				</GridItem>
			</GridContainer>

			{attachments.length > 0 && (
				<Box>
					<Heading bold variant="h5" mt={1} mb={0.35}>
						Attachments
					</Heading>
					{attachments.map(({ name, size }, i) => (
						<Box
							key={`${name}-${size}-${i}`}
							display="flex"
							alignItems="center"
							gap={1}
						>
							<Paragraph small>{name}</Paragraph>
							<IconButton
								icon="trash-alt"
								onClick={() => handleRemoveAttachment(i)}
								color="primary"
								size="sm"
								title="Remove attachment"
							/>
						</Box>
					))}
				</Box>
			)}

			<Box display="flex" justifyContent="flex-start" alignItems="center" gap={2} my={1}>
				<FileInput
					onChange={handleAttachmentSelect}
					fileTypes={partOrderAttachmentFileTypes}
					buttonVariant="text"
					icon="paperclip-vertical"
					label="Attach file to order"
					width="auto"
				/>
			</Box>

			<Box
				display="flex"
				columnGap={2}
				rowGap={1}
				flexWrap="wrap"
				mb={4}
				smProps={{ justifyContent: "space-between" }}
				xsProps={{ flexDirection: "column" }}
			>
				<div>
					<Label>Subtotal</Label>
					<Price price={orderSubtotal} />
				</div>
				<div>
					<Label>Taxes</Label>
					<Price price={orderTaxes} />
				</div>
				{!!appliedPromotions.length && (
					<div>
						<Label>Promotions</Label>
						<Price price={orderDiscounts * -1} />
					</div>
				)}
				<Box bl={1} pl={2} xsProps={{ bl: 0, pl: 0, bt: 1, pt: 1 }}>
					<Label>Total</Label>
					<Price price={orderTotal} />
				</Box>
			</Box>

			{!!submissionErrorText && (
				<ErrorText my={1} textAlign="center">
					{submissionErrorText}
				</ErrorText>
			)}

			<Box display="flex" justifyContent="center">
				<Button
					variant="primary-cta"
					onClick={handleSubmit(onSubmit, onInvalid)}
					fillContainer
					containerProps={{ maxWidth: 50 }}
					isLoading={isSaving("submitting")}
					disabled={submitCount > 0 && !canSubmit}
				>
					Create order
				</Button>
			</Box>

			<ConfirmExitIntent
				when={!billToLoading && lineItemsToSubmit.length > 0 && !isSaving("submitting")}
				message="Leave without completing order?"
			/>
		</FormProvider>
	)
}
